import { ethers } from 'ethers';
import Web3Modal from "web3modal";



require("dotenv").config();
const alchemyKey = process.env.REACT_APP_Dev_Mode ==1 ? process.env.REACT_APP_Test_Network_URL : process.env.REACT_APP_Main_Network_URL;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require("./Contract.json");
const contractAddress = process.env.REACT_APP_contractAddress;

const CheckWhiteList = process.env.REACT_APP_Check_WhiteList;


const NetworkID =  process.env.REACT_APP_Dev_Mode ==1 ? process.env.REACT_APP_Test_Network_Version : process.env.REACT_APP_Main_Network_Version;
const NetworkChainID = process.env.REACT_APP_Dev_Mode ==1 ? process.env.REACT_APP_Test_Network_chainId : process.env.REACT_APP_Main_Network_chainId;
const NetworkName = process.env.REACT_APP_Dev_Mode ==1 ? process.env.REACT_APP_Test_Network_Name : process.env.REACT_APP_Main_Network_Name;
const EtherscanURL = process.env.REACT_APP_Dev_Mode ==1 ? process.env.REACT_APP_Test_Etherscan_url : process.env.REACT_APP_Main_Etherscan_url
const WalletMxMint = Number(process.env.REACT_APP_Wallet_Max_Mint_Amount);
const CMaxSpp = Number(process.env.REACT_APP_Max_Supply);

// const TestNetworkVersion = Number(process.env.REACT_APP_Test_Network_Version) ;
// const MainNetworkVersion = Number(process.env.REACT_APP_Main_Network_Version) ;

export const _Contract = new web3.eth.Contract(
  contractABI,
  contractAddress
);

export const walletEthBalance = async (address) =>{
  const b= await web3.eth.getBalance(address);
  return b;
}

export const loadStatus = async () => {
  const p = await _Contract.methods.paused().call();
  return !p;
};
export const loadMaxSupply = async () => {
  const ms = await _Contract.methods.maxSupply().call();
  return ms;
};
export const loadTotalSupply = async () => {
  const ts = await _Contract.methods.totalSupply().call();
  return ts;
};
export const loadMaxMintAmount = async () => {
  const mna = await _Contract.methods.maxTokensMintedPerAddress().call();
  return mna;
};
export const loadMinEthBalance = async () => {
  const meb = await _Contract.methods.minmiumEthBalance().call();
  return meb;
};
export const loadTokensMintedPerAddress  = async (address) => {
  const tma = await _Contract.methods.tokensMintedPerAddress(address).call();
  return tma;
};
export const loadSymbol = async () => {
  const smb = await _Contract.methods.symbol().call();
  return smb;
};
export const loadBalanceOf = async (address) => {
  const bo = await _Contract.methods.balanceOf(address).call();
  return bo;
};

let wcprovider=null;
const WalletConnectProvider = window.WalletConnectProvider.default;
const providerOptions = {
  walletconnect: {
      package: WalletConnectProvider,
      options: {
          rpc: {
              1 : process.env.REACT_APP_Main_Network_URL,
              5 : process.env.REACT_APP_Test_Network_URL
          }
      }
  }
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  disableInjectedProvider: false,
  theme: {
      background: "#019DDA",
      main: "#000",
      secondary: "#000",
      border: "transparent",
      hover: "#fff"
  }
});

export const connectWallet = async () => {

  try {
    const _provider = await web3Modal.connect();
    wcprovider = _provider;

    // _provider.on("accountsChanged", (accounts) => {
    //     fetchAccountData();
    // });
    // _provider.on("chainChanged", (chainId) => {
    //     fetchAccountData();
    // });
    // _provider.on("disconnect", () => {
    //     onDisconnect();
    // });
    return fetchAccountData();
  } catch (e) {
      //console.log("Could not get a wallet connection", e);
      return {
              address: "",
              status: "Wallet Connection Error!" ,
              WAuthorized :"",
              Provider: null,
              Balance:0
            };
  }


};

export const onDisconnect = async () => {
  await web3Modal.clearCachedProvider();
  wcprovider = null;
}

export const fetchAccountData = async () => {
  try
  {
  const library = new ethers.providers.Web3Provider(wcprovider);
  const _accounts = await library.listAccounts();
  const _Netowrk = await library.getNetwork();
  if (_Netowrk.chainId.toString() !== NetworkID) {
     if( await switchNetwork() === false)
     {
         return {
                address: "",
                status: "😥 Unable to switch network to " + NetworkName,
                WAuthorized :"",
                Provider: null,
                Balance:0
                };
     }
  }
  const _selectedAccount = _accounts.length > 0 ? _accounts[0] : "";
  return  {
          status: await walletEthBalance(_selectedAccount) >= await loadMinEthBalance() ? "" : "❌ You don't have the minimum ETH required in your wallet",
          address: _selectedAccount,
          WAuthorized : await CheckWalletAddress(_selectedAccount),
          Provider: wcprovider,
          Balance: await walletEthBalance(_selectedAccount)
        };
  }
  catch{
    return  {
      status: "Please connect using the top right button.",
      address: "",
      WAuthorized : "",
      Provider: "",
      Balance:0
    };
  }
}

const switchNetwork =async () => {
  try {
      await wcprovider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: NetworkChainID }],
      });
      return true;
  } catch (switchError) {
      return false;
  }
}

export const CheckWalletAddress = async (address) => {
  if(CheckWhiteList === "yes")
  {
    var waresponse= "false";
    try{
     await fetch('./api/whitelist/'  + address.toLowerCase() + '.html' ,{method: "GET" , headers : {"Accept": "application/json","Access-Control-Allow-Origin" :"*","Access-Control-Allow-Headers" : "Content-Type" , "Access-Control-Allow-Methods" :"GET" } })
      .then((response)=>{
        //console.log('whitelist',response.status);
        waresponse = response.status === 404 ? "false" : "true";
      });
      // .then(function(jsonresponse) {
      //   if(jsonresponse.isWhitelist === true){
      //     waresponse="true";
      //   }
      //   else
      //   {
      //     waresponse="false";
      //   }
      // })
      // .catch((err) => {
      //   waresponse="false";
    // });
    }
    catch {
      waresponse="false";
    }
        return waresponse;
  }
  else
  {
    return  "true";
  }
};

export const updateMint = async (address,txHash,amnt) => {
    // try{
    //   const m = process.env.REACT_APP_UpdateMintURL + "_Address=" + encodeURIComponent(address) + "&_TxHash=" + encodeURIComponent(txHash) + "&_MintURL=" + encodeURIComponent(window.location) +"&_MintAmount=" + encodeURIComponent(amnt);
    //   await fetch( m ,{method: "GET" , headers : {"Accept": "application/json","Access-Control-Allow-Origin" :"*","Access-Control-Allow-Headers" : "Content-Type" , "Access-Control-Allow-Methods" :"GET" } });
    // }
    // catch {
    // }
};

export const getCurrentWalletConnected = async () => {
  if (web3Modal.cachedProvider) {
   return await connectWallet();
  }
  else
  {
    return {
      address: "",
      status: "" ,
      WAuthorized :"",
      Provider: null
      
    };
  }
};

export const mintFunction = async (address, message) => {
  const CStatus = await loadStatus();
  if(CStatus===false)
  {
    return {status: "❌ NFT Minting not started"};
  }
  
  
  if (typeof (wcprovider) == 'undefined' || wcprovider == null || address==="") {
    connectWallet();
    return {
      status: "Please connect your wallet using the top right button"
    };
}
  
  const wauth= await CheckWalletAddress(address);
  if(wauth === "false")
  {
    return {status: "❌ Wallet is not whitelisted"};
  }

  const bo= Number(await loadMaxMintAmount(address));
  const ta= Number(await loadTokensMintedPerAddress(address));
  if( (ta + Number(message)) > bo )
  {
    if(bo===WalletMxMint)
    {
      return {status: "❌ you have exceeded the max mint allowed"};
    }
    else
    {
      return {status: "❌ your maximum NFTs to mint is: " + (bo > WalletMxMint ? 0 : WalletMxMint - bo)};
    }
  }
  const ts= Number(await loadTotalSupply());
  if( (ts + Number(message)) > CMaxSpp)
  {
    return {status: "❌ NFT Minting is Paused."};
  }

  const _library = new ethers.providers.Web3Provider(wcprovider);
  const _account = await _library.listAccounts();
  const _Netwrk = await _library.getNetwork();

  if(_Netwrk.chainId.toString() !== NetworkID.toString())
  {
    return {
      status:
        "Please switch the network in your wallet to " + NetworkName,
    };
  }

  if(_account.length === 0){
    return {
      status: "Please connect your wallet using the top right button"
    };
  }
  if(await walletEthBalance(address) < await loadMinEthBalance() )
  {
    return {
      status : "❌ You don't have the minimum ETH balance required in your wallet"
    };
  }
  if (message.trim() === "0") {
    return {
      status: "❌ ",
    };
  }
  

  const transactionParameters = {
    to: contractAddress, 
    from: address, 
    data: _Contract.methods.mint().encodeABI(),
  };

  
  try {
    const txHash = await wcprovider.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    await updateMint(address,txHash,message);
    return {
      status: (
        <span>
          Please check the transaction in your wallet, or click <a target="_blank" href={`${EtherscanURL + txHash}`}>here</a>  to view the transaction on ehterscan.<br/>
        </span>
      ),
    };
  } catch (error) {
    return {
      status: "😥 Wallet Error: " + error.message,
    };
  }
};


export const addTokenMetamask = async() => {
  const smb = await loadSymbol();
   await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20', 
      options: {
        address: contractAddress, 
        symbol: smb, 
        decimals: 0, 
      },
    },
  });
}